import Vue from 'vue'

import AppSKW from './app-skw.vue'
import AppACS from './app-acs.vue'
import AppPrint from './app-print.vue'

import router from './router'
import store from './store'
import axios from 'axios'
import { sync } from 'vuex-router-sync'
import { FontAwesomeIcon } from 'common/icons'
import VueAuth from '@websanova/vue-auth'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueMoment from 'vue-moment'
import Loading from 'common/components/loading'
import LoadingSmall from 'common/components/loading-small'
import FavouriteButton from 'common/components/common/favourite-button'
import SignalR from 'common/signalr';
import SignalRFake from 'common/signalr.dev-fake';


import StaggeredTransitionGroup from 'common/components/staggered-transition-group'
import SlideUpDown from 'vue-slide-up-down'
import moment from 'moment-timezone';

import 'common/css/skw-light-bs.scss'
import 'common/css/skw-dark-bs.scss'
import 'common/css/acs-light-bs.scss'
import 'common/css/acs-dark-bs.scss'
import 'common/css/common.scss'
import 'common/css/common-light.scss'
import 'common/css/common-dark.scss'
import 'common/css/skw-common.scss'
import 'common/css/skw-light.scss'
import 'common/css/skw-dark.scss'
import 'common/css/acs-common.scss'
import 'common/css/acs-light.scss'
import 'common/css/acs-dark.scss'
import 'common/common.css'

import websiteType from 'common/website-type';
Vue.use(websiteType);

import debug from 'common/debug';
Vue.use(debug);

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import { loadLocaleMessages } from 'common/i18n'

var i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
});




if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}




Vue.component('staggered-transition-group', StaggeredTransitionGroup);
Vue.component('favourite-button', FavouriteButton);
Vue.component('slide-up-down', SlideUpDown)
Vue.component('icon', FontAwesomeIcon)
Vue.component('loading', Loading);
Vue.component('loading-small', LoadingSmall);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;


Vue.use(VueAxios, axios);
Vue.use(Notifications);


if (process.env.VUE_APP_PROJECT_ACS == "true") {
    Vue.use(BootstrapVue, {
        BModal: {
            okTitleHtml: '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" style="top: -2px; position: relative;" fill="currentColor" viewBox="0 -960 960 960"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>'
                + ' ' + i18n.t('actions.ok'),
            cancelTitleHtml: '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" style="top: -2px; position: relative;" fill="currentColor" viewBox="0 -960 960 960"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>'
                + ' ' + i18n.t('actions.cancel'),
            okOnly: true
        }
    });
}
else {
    Vue.use(BootstrapVue, {
        BModal: {
            okTitleHtml: i18n.t('actions.ok'),
            cancelTitleHtml: i18n.t('actions.cancel')
        }
    });
}



Vue.use(BootstrapVueIcons);
Vue.use(VueMoment);

// Use our common-icon to replace b-icon of bootstrap-vue
import CommonIcon from 'common/components/common/common-icon';
Vue.component('common-icon', CommonIcon);
Vue.component('b-icon', CommonIcon);



import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, numeric, is, min_value, max_value, double, max, email } from 'vee-validate/dist/rules';
import FormErrors from 'common/components/common/form-errors'
import ValidationErrors from 'common/components/common/validation-errors'

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
Vue.component('form-errors', FormErrors);
Vue.component('validation-errors', ValidationErrors);

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('required', required);
extend('max', max);
extend('numeric', numeric);
extend('double', double);
extend('is', is);
extend('min_value', min_value);
extend('max_value', max_value);
extend('email', email);

import * as validators from 'common/validation';
var validatorsObject = {};
Object.entries(validators).forEach(([name, exported]) => validatorsObject[name] = exported);

extend('unique-number-plate', validatorsObject['uniqueNumberPlateValidator'])
extend('number-plate', validatorsObject['numberPlateValidator']);
extend('udn-in-stockroom', validatorsObject['udnInStockroomValidator']);
extend('unique-client-name', validatorsObject['uniqueClientNameValidator']);
extend('unique-email', validatorsObject['uniqueEmailValidator']);
extend('unique-door-description-apex', validatorsObject['uniqueDoorDescriptionApex']);
extend('unique-door-number-apex', validatorsObject['uniqueDoorNumberApex']);
extend('unique-username', validatorsObject['uniqueUsernameValidator']);
extend('udn', validatorsObject['udnValidator']);
extend('starts_with', validatorsObject['startsWithValidator']);
extend('udn-device-type', validatorsObject['udnDeviceTypeValidator']);
extend('unique-cloud-token-prefix', validatorsObject['uniqueCloudTokenPrefixValidator']);
extend('unique-domain', validatorsObject['uniqueDomainValidator']);
extend('unique-area-name', validatorsObject['uniqueAreaNameValidator']);
extend('unique-group-name', validatorsObject['uniqueGroupNameValidator']);
extend('unique-time-profile-name', validatorsObject['uniqueTimeProfileNameValidator']);
extend('unique-notice-message-name', validatorsObject['uniqueNoticeMessageNameValidator']);
extend('unique-notice-board-name', validatorsObject['uniqueNoticeBoardNameValidator']);
extend('keycode', validatorsObject['keycodeValidator']);
extend('not_in_twice', validatorsObject['notInTwiceValidator']);
extend('unique_fob_keycode', validatorsObject['uniqueFobKeycodeValidator']);
extend('unique_fob_reference', validatorsObject['uniqueFobReferenceValidator']);
extend('unique-person-name', validatorsObject['uniquePersonNameValidator']);
extend('unique-person-email', validatorsObject['uniquePersonEmailValidator']);
extend('unique-door-unlock-invitation-name', validatorsObject['uniqueDoorUnlockInvitationNameValidator']);
extend('unique-sim-reference', validatorsObject['uniqueSimReferenceValidator']);
extend('unique-input-name', validatorsObject['uniqueInputNameValidator']);
extend('unique-output-name', validators['uniqueOutputNameValidator']);
extend('truthy', validators['truthyValidator']);
extend('falsy', validators['falsyValidator']);
extend('prop-is', validators['propIsValidator']);
extend('fob-keycode-exist', validators['fobKeycodeExistValidator']);
extend('password-at-least-8', validatorsObject['passwordAtLeast8Validator']);
extend('password-not-common', validatorsObject['passwordNotCommonValidator']);
extend('unique-user-link', validatorsObject['uniqueUserLinkValidator']);
extend('array-include', validatorsObject['arrayIncludeValidator']);






Vue.directive('focusNextOnEnter', {
    inserted: function (el, binding, vnode) {
        el.addEventListener('keyup', (ev) => {
            if (ev.keyCode === 13) {
                if (binding.value) {
                    vnode.context.$refs[binding.value].focus()
                    return
                }
                if (!el.form) {
                    return
                }
                const inputElements = Array.from(el.form.querySelectorAll('input:not([disabled]):not([readonly])'))
                const currentIndex = inputElements.indexOf(el);

                var index = currentIndex < inputElements.length - 1 ? currentIndex + 1 : 0;
                if (index > 0)
                    inputElements[index].focus()
                else
                    inputElements[currentIndex].blur();
            }
        })
    }
})


Vue.router = router;



Vue.use(VueAuth, {
    auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
    loginData: { url: '/authentication/request-token', method: 'POST', redirect: '/', fetchUser: true },
    fetchData: { url: '/authentication/user', method: 'GET', enabled: true },
    refreshData: { url: '/authentication/refresh-token', method: 'GET', enabled: true, interval: 30 }
});

if (process.env.NODE_ENV == 'production') {
    if (window.location.pathname != '/print')
        Vue.use(SignalR);
}
else
{
    Vue.use(SignalR);

    // DEBUG use fake SignalR in development environment
    // Vue.use(SignalRFake);
}



Vue.filter('formatDateTime', function (value) {
    if (value) {
        var m = (value instanceof Date) ? moment(value) : moment(String(value));
        return m.format('DD/MM/YYYY HH:mm');
    }
});


Vue.filter('formatDate', function (value) {
    if (value) {
        var m = (value instanceof Date) ? moment(value) : moment(String(value));
        return m.format('DD/MM/YYYY');
    }
});

Vue.filter('formatTime', function (value) {
    if (value) {
        var m = (value instanceof Date) ? moment(value) : moment(String(value).replace("Z", ""));
        return m.format('H:mm:ss A');
    }
});

Vue.filter('formatPrice', function (value) {
    if (value) {
        return new Intl
            .NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2 })
            .format(value);
    }
});

sync(store, router)

Vue.config.productionTip = false;

var appVue = null;


if (window.location.pathname == '/print') {
    var app = new Vue({
        render: h => h(AppPrint)
    }).$mount('#app');
} else {
    if (process.env.VUE_APP_PROJECT_ACS == "true") {
        var app = new Vue({
            router,
            store,
            i18n,
            render: h => h(AppACS)
        }).$mount('#app');
    } else {
        var app = new Vue({
            router,
            store,
            i18n,
            render: h => h(AppSKW)
        }).$mount('#app')
    }
}

app.decodeJwt = function (token) {
    var rtn = null;

    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        rtn = JSON.parse(jsonPayload);
    } catch (error) { }

    return rtn;
};

export {
    app,
    router,
    store
}

router.beforeEach((to, from, next) => {
    var redirectPath = null;

    if (app.$auth) {
        var token = app.$auth.token();
        if (token) {
            var actionRequired = app.decodeJwt(token)['action-required'];
            if (actionRequired == 'change-password') {
                var allowedPaths = [
                    '/account/change-password',
                    '/register',
                    '/login',
                    '/about',
                    '/blank',
                ];
                if (!allowedPaths.includes(to.path))
                    redirectPath = '/account/change-password';
            }
        }
    }

    if (redirectPath) next(redirectPath);
    else next();
});

axios.interceptors.response.use(
    response => response,
    error => {
        
        if (error.response && error.response.status === 451) {
            router.replace({
                name: 'client-mismatch-error',
                params: { error, url: router.currentRoute }
            });
        }

        // non-payment: they should be kicked out to the login screen
        if (error.response && error.response.status === 402) {
            router.push({
                path: '/login'
            });
        }

        return Promise.reject(error);
    }
);



