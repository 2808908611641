<template>
    <div v-show="item.menuItems === undefined ||
                 item.neverHide ||
                 (item.menuItems && 
                 !(item.menuItems.every(subItem =>
                    !(subItem.permissions === undefined || 
                    subItem.permissions.length == 0 ||
                    $auth.check(subItem.permissions, 'permissions')))))"
        class="my-2" >
        <a :class="itemClass" 
           style="border-radius: 100px; min-height: 2rem;"
           v-if="item.to && item.target"
           :href="this.item.to"
           :target="item.target">
            <common-icon :class="['align-bottom mt-1', depth > 0 ? 'text-muted ml-3':'']" :icon="item.icon" font-scale="1" />
            
            <span v-if="item.text && wide" :class="['ml-3 flex-fill font-weight-bold', depth > 0 ? '':'top-level-item']" style="margin-top:1px;">{{ item.text }}</span>
        </a>

        <router-link :class="itemClass" style="border-radius: 100px; min-height: 2rem;"
                     v-else-if="item.to" :to="this.item.to" :target="item.target">

            <common-icon :class="['align-bottom mt-1', depth > 0 ? 'text-muted ml-3':'', !wide && notificationDetails[item.text] ? 'item-icon' : '']" :icon="item.icon" font-scale="1" />

            <span v-if="item.text && wide" :class="['ml-3 flex-fill font-weight-bold', depth > 0 ? '':'top-level-item']" style="margin-top:1px;">{{ item.text }}</span>
            <div v-if="notificationDetails[item.text] && wide" class="notification-badge">{{notificationDetails[item.text]}}</div>
        </router-link>

        <div :class="itemClass" 
             style="border-radius: 100px; min-height: 2rem;" role="button"
             v-else-if="item.type === 'switchable-client-filter'"
             @click="itemClick">
            <common-icon class="align-bottom mt-1" :icon="item.icon" font-scale="1" />

            <input type="text"
                   class="ml-3 flex-fill border-0 bg-transparent text-dark switchable-client-filter"
                   v-if="wide"
                   v-model="item.text" 
                   placeholder="Search" ref="switchableClientFilter" />
        </div>

        <div :class="itemClass"
             style="border-radius: 100px; min-height: 2rem; margin-left: 14px !important; margin-right: 12px !important;" role="button"
             v-else
             @click="itemClick">

            <div class="menu-icon">
                <common-icon :class="['align-bottom mt-1', depth > 0 ? '':'']"
                             :style="'margin-left: -7px;'"
                             :icon="item.icon"
                             font-scale="1" />
                <div class="notification-dot" v-if="itemClass.includes('has-notifications')"></div>
            </div>
            


            <span v-if="item.text && wide" :class="['ml-3 flex-fill font-weight-bold', depth > 0 ? '':'top-level-item']" style="margin-top:1px;">{{ item.text }}</span>
            <b-skeleton v-else-if="wide" class="ml-3 mt-1 flex-fill" />

            <div class="mt-1 p-1 border grey-4" style="border-radius: 100px; border-color: var(--grey-4) !important; height: 18px; line-height: 0px;"
                 v-if="showExpand && wide" role="button" @click.stop="expandClick">
                <b-icon icon="caret-right-fill" font-scale="0.5" />
            </div>
            <div class="mt-1 p-1 border grey-4" style="border-radius: 100px; border-color: var(--grey-4) !important; height: 18px; line-height: 0px;"
                 v-if="showCollapse && wide" role="button" @click.stop="collapseClick">
                <b-icon icon="caret-down-fill" font-scale="0.5" />
            </div>
        </div>

        <transition name="slide" mode="out-in" v-if="item.menuItems && item.menuItems.length">
            <div v-if="item.expanded" class="overflow-hidden">
                <side-menu-item-acs v-if="subItem.permissions === undefined || subItem.permissions.length == 0 || $auth.check(subItem.permissions, 'permissions')"
                                    class="text-muted"
                                    v-for="(subItem, index) in item.menuItems"
                                    :key="index"
                                    :item="subItem"
                                    :wide="wide"
                                    :depth="depth + 1"
                                    @expand="subItemExpand"
                                    @collapse="subItemCollapse"
                                    @widen="subItemWiden" 
                                    @narrow="subItemNarrow"
                                    :notification-details="notificationDetails"/>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'side-menu-item-acs',

        props: {
            item: null,
            wide: Boolean,
            depth: Number,
            notificationDetails:[]
        },

        emits: [
            'expand', // (menuItem) => menuItem request expand, the parent component should handle it
            'collapse', // (menuItem) => menuItem request collapse, the parent component should handle it
            'widen', // (menuItem) => menuItem request widen, bubble up to app-acs.vue to handle it
            'narrow', // (menuItem) => menuItem request narrow down, bubble up to app-acs.vue to handle it
        ],

        data: function () {
            return {
            }
        },

        computed: {
            itemClass() {
                var rtn = ['mx-2', 'my-1', 'd-flex', 'py-1', 'menu-item'];

                if (this.wide) {
                    rtn.push('pl-3');
                    rtn.push('pr-2');
                    rtn.push('menu-item-wide');
                } else {
                    rtn.push('pl-3');
                }
                
                if (this.item.to && this.$route && this.item.to === this.$route.path) {
                    rtn.push('bg-primary-bg');
                    rtn.push('text-primary');
                } else if (this.item.classes) {
                    rtn = rtn.concat(this.item.classes)
                }
                else if (this.item.to) {
                    if(this.depth > 0)
                        rtn.push('text-muted');
                    else
                        rtn.push('grey-6');
                }

                return rtn;
            },

            showExpand() {
                return this.item.menuItems && this.item.menuItems.length && !this.item.expanded;
            },

            showCollapse() {
                return this.item.menuItems && this.item.menuItems.length && this.item.expanded;
            },
        },

        methods: {
            itemClick() {
                if (this.item.to) {
                    if (this.item.target === '_blank') {
                        window.open(this.item.to, '_blank');
                    } else {
                        this.$router.push(this.item.to);
                    }
                    this.$emit('narrow', this.item);
                } else if (this.item.menuItems) {
                    if (this.item.expanded) this.$emit('collapse', this.item);
                    else {
                        this.$emit('widen', this.item);
                        this.$emit('expand', this.item);
                    }
                } else if (this.item.type === 'switchable-client-filter') {
                    if (this.$refs.switchableClientFilter && document.activeElement !== this.$refs.switchableClientFilter) {
                        this.$refs.switchableClientFilter.select();
                    }
                } else if (this.item.type === 'switchable-client') {
                    this.$store.commit('setBrowsingClient', this.item.clientId);
                    this.$emit('narrow', this.item);
                }
            },

            expandClick() {
                this.$emit('expand', this.item);
            },

            collapseClick() {
                this.$emit('collapse', this.item);
            },

            subItemExpand(subItem) {
                for (var menuItem of this.item.menuItems) {
                    if (menuItem == subItem) menuItem.expanded = true;
                    else menuItem.expanded = false;
                }
            },

            subItemCollapse(subItem) {
                subItem.expanded = false;
            },

            subItemWiden(subItem) {
                this.$emit('widen', subItem);
            },

            subItemNarrow(subItem) {
                this.$emit('narrow', subItem);
            },
        },

        async mounted() {
            if (this.item.type === 'switchable-client-filter') {
                while (!this.$refs.switchableClientFilter) {
                    // wait for components rendering
                    await new Promise((resolve) => window.setTimeout(resolve, 10));
                    await this.$nextTick();
                }

                if (this.$refs.switchableClientFilter && document.activeElement !== this.$refs.switchableClientFilter) {
                    this.$refs.switchableClientFilter.select();
                }
            }
        },
    }
</script>

<style scoped>
    

    .menu-item:focus-within {
        /* background-color: var(--light) !important;*/
        background-color: var(--primary-bg);
    }

    .menu-item:hover {
        /*background-color: var(--light) !important;*/
        text-decoration: none !important;
        background-color: var(--primary-bg);
    }


    .switchable-client-filter:focus {
        outline: none;
    }

    .top-level-item{
        font-weight:bold;
    }

    .text-muted:hover {
        color:#333333 !important;
    }

    .item-icon:after {
        right: 0;
        top: 0;
        content: '';
        height: 2.5px;
        width: 2.5px;
        position: absolute;
        background: orange;
        border-radius: 50%;
    }
    .border {
        border-radius: 100px;
        border-color: var(--black) !important;
        height: 18px;
        line-height: 0px;
    }

    .menu-icon {
        position: relative;
    }

    .notification-dot {
        content: "";
        border-radius: 50%;
        height: 5px;
        width: 5px;
        top: 3px;
        right: -3px;
        background: #E65A23;
        position: absolute;
    }        

    .notification-badge {
        background-color: #E65A23;
        border-color: #E65A23 !important;
        text-align:center;
        color: white;
        border-radius: 50%;
        width:1.5rem;
        line-height:1.5rem;
    }

</style>