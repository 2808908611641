<template>
    <div>app-print</div>
</template>

<script>
    export default {
        data: function () {
            return {
            };
        },
    };
</script>