<template>
    <div class="d-flex flex-column" style="min-height: 100%; height: 100%;">

        <header class="sticky-top bg-white border-bottom d-flex" style="min-height: 110px; height: 110px;"
                v-if="wide">
            <div class="my-auto ml-3 pt-2">
                <div class="came">
                    {{ $t('nav.came') }}
                </div>
                <div class="text-muted small mt-n2">{{ $t('nav.acs-cloud') }}</div>
            </div>
            <came-logo class="my-auto" width="48" height="48" fill="#009fe3" />
            <router-link class="stretched-link" to="/dashboard" />
        </header>

        <header class="sticky-top bg-white border-bottom d-flex" style="min-height: 110px; height: 110px;"
                v-else>
            <div class="m-auto bg-primary p-1" style="border-radius: 100px;">
                <came-logo width="36" height="36" fill="#ffffff" />
            </div>
            <router-link class="stretched-link" to="/" />
        </header>

        <div class="mb-auto overflow-auto mt-3" style="font-size: 14px;">
            <side-menu-item-acs v-for="(subItem, index) in menuItems"
                                :key="index"
                                :item="subItem"
                                :wide="wide"
                                :depth="0"
                                @expand="subItemExpand"
                                @collapse="subItemCollapse"
                                @widen="subItemWiden"
                                @narrow="subItemNarrow"
                                :notification-details="notificationDetails"/>
        </div>
        
        <universal-search-modal></universal-search-modal>

        <div class="px-1 py-2 text-center" v-if="wide">
            <light-dark-mode-toggle />
        </div>

        <div class="py-2 d-flex" v-else>
            <div class="bg-light text-center p-2 mx-auto" style="border-radius: 100px; line-height: 0;"
                 role="button" @click="darkMode = !darkMode">
                <b-icon icon="sun" font-scale="1.5" v-if="!darkMode" />
                <b-icon icon="moon" font-scale="1.5" v-if="darkMode" />
            </div>
        </div>

        <div class="border-top btn-group dropup" style="min-height: 60px; height: 60px">
            <button class="btn dropdown-toggle border-0 bg-transparent text-black d-flex w-100" data-toggle="dropdown">

                <!--<identicon-image hash-string="CAF64B30E514424" :image-size="42"
                             :class="['float-left', 'my-auto', 'mr-2', wide ? 'ml-3' : 'ml-0']"
                             style="border-radius: 100px; border: 2px solid var(--border);" />-->


                <initials-circle :image-size="42" :first-name="user.user.firstName" :surname="user.user.surname" />

                <div v-if="wide" class="small my-auto text-left">
                    <strong>{{ user.user.displayName }}</strong>
                    <br />
                    <span>{{ user.user.email }}</span>
                </div>
            </button>
            <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/account">{{ $t('nav.my-account') }}</router-link>
                <router-link class="dropdown-item" to="/my-client">{{ $t('actions.my') }} {{$t('objects.client')}}</router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click="logoutClick" role="button">{{ $t('nav.logout') }}</a>
            </div>
        </div>


    </div>
</template>

<script>
    import IdenticonImage from 'common/components/common/identicon-image';
    import InitialsCircle from 'common/components/common/initials-circle';


    import SideMenuItemAcs from './side-menu-item-acs';
    import LightDarkModeToggle from 'common/components/common/light-dark-mode-toggle';
    import UniversalSearchModal from 'common/components/universal-search/universal-search-modal';
    import favourite from 'common/mixins/favourite';
    import MenuItemMixin from 'common/mixins/menu-item-acs';
    import CameLogo from 'common/components/common/came-logo';

    export default {
        mixins: [MenuItemMixin],
        components: {
            'identicon-image': IdenticonImage,
            'side-menu-item-acs': SideMenuItemAcs,
            'light-dark-mode-toggle': LightDarkModeToggle,
            'came-logo': CameLogo,
            'initials-circle': InitialsCircle,
            'universal-search-modal': UniversalSearchModal
        },

        props: {
            wide: true,
        },

        emits: [
            'widen', // (menuItem) => menuItem request widen, bubble up to app-acs.vue to handle it
            'narrow', // (menuItem) => menuItem request narrow down, bubble up to app-acs.vue to handle it
        ],

        data: function () {
            return {
                switchableClientsFilter: '',
                switchableClients: [],
                darkMode: false,
                unacknowledgedAlarms: [],
                unacknowledgedAlerts: [],
                unacknowledgedMessages: [],
                controllerIds: [],
            }
        },

        computed: {
            notificationDetails() {
                var notificationDetails = {};

                notificationDetails[this.$t('nav.alarms')] = this.unacknowledgedAlarms.length;
                notificationDetails[this.$t('nav.alerts')] = this.unacknowledgedAlerts.length;
                notificationDetails[this.$t('nav.client-messages')] = this.unacknowledgedMessages.length;

                return notificationDetails;
            },
            hasNotifications() {
                return this.unacknowledgedAlarms.length > 0 || this.unacknowledgedAlerts.length > 0 || this.unacknowledgedMessages.length > 0
            },
            user() {
                return this.$auth.user();
            },
            browsingClient() {
                if (!this.switchableClients.length) return null;

                var workingClientId = this.$auth.user().userData.workingClientId;
                var rtn = this.switchableClients.find(c => c.clientId == workingClientId);

                if (!rtn) return null;
                return rtn;
            }
        },

        methods: {
            
            subItemExpand(subItem) {
                for (var menuItem of this.menuItems) {
                    if (menuItem == subItem) menuItem.expanded = true;
                    else menuItem.expanded = false;
                }
                this.$emit('widen', subItem);
            },
            setCustomerName() {
                this.menuItems[this.$t('nav.control-panel')][this.$t('nav.control-panel')]
            },
            subItemCollapse(subItem) {
                subItem.expanded = false;
            },

            subItemWiden(subItem) {
                this.$emit('widen', subItem);
            },

            subItemNarrow(subItem) {
                this.$emit('narrow', subItem);
            },

            logoutClick: async function () {
                try {
                    this.$auth.logout({
                        makeRequest: false,
                        params: {},
                        success: () => {
                            this.$notify({ type: 'alert-success', text: 'Successfully logged out' });
                        },
                        error: (response) => this.$notify({ type: 'alert-danger', title: 'Failed to logout', text: response.data.message }),
                        redirect: '/login',
                    });
                } catch (error) {
                    this.$debugLog('Redirect error:', error)
                }
            },

            async handleUserChange() {
                var menuBrowsing = this.menuItems.find(item => item.type && item.type === 'browsing-client');
                if (!menuBrowsing) return;

                menuBrowsing.text = null;
                menuBrowsing.expanded = false;
                menuBrowsing.menuItems = null;

                if (this.user && this.user.user && this.user.user.userId) {
                    this.switchableClients = (await this.$http.get(`/user/${this.user.user.userId}/switchable-clients`)).data;

                    menuBrowsing.text = null;
                    menuBrowsing.expanded = false;
                    menuBrowsing.menuItems = [];
                } else {
                    this.switchableClients = [];
                }

                // Wait for this.browsingClient change
                
                await this.$nextTick();

                this.filterSwitchableClients();
            },
            async loadFavouriteData() {

                var menuItem = this.$t('nav.favourites');

                var favourites = (await this.$http.get(`/favourite/user-favourites`)).data;
                var favouriteLink = '/settings/user/' + this.user.user.userId + '#favourites';
                var favouriteIndex = this.menuItems.map(i => i.text).indexOf(menuItem);

                if (favouriteIndex == -1)
                    return;

                var favouriteMenuItem = this.menuItems[favouriteIndex];

                favouriteMenuItem.menuItems = [];

                favouriteMenuItem.menuItems.push({
                    text: "All Favourites",
                    icon: 'heart-fill',
                    to: favouriteLink,
                });

                for (var i = 0; i < favourites.length; i++) {
                    favouriteMenuItem.menuItems.push({
                        text: favourites[i].name,
                        icon: favourites[i].icon,
                        to: favourites[i].url,
                    });
                }

                this.$set(this.menuItems, favouriteIndex, favouriteMenuItem)

            },
            filterSwitchableClients() {
                var menuBrowsing = this.menuItems.find(item => item.type && item.type === 'browsing-client');
                if (!menuBrowsing) return;

                var filteredClients;
                if (this.switchableClientsFilter) filteredClients = this.switchableClients.filter(c =>
                    c.name.toLowerCase().indexOf(this.switchableClientsFilter.toLowerCase()) > -1 &&
                    c.clientId !== this.browsingClient.clientId
                )
                else filteredClients = this.switchableClients;

                menuBrowsing.menuItems.splice(1, menuBrowsing.menuItems.length - 1);
                Array.prototype.push.apply(menuBrowsing.menuItems, filteredClients.map(item => {
                    return {
                        type: 'switchable-client',
                        text: item.name,
                        icon: 'person',
                        clientId: item.clientId,
                    };
                }));
            },
            updateClientName() {
               var text = this.$t("nav.installers");

                if (this.user.browsingClient.isEngineer)
                    text = this.$t("nav.site-owners");

                var controlPanelMenu = this.menuItems.find(item => item.text && item.text === this.$t("nav.sites"));                

                if (controlPanelMenu == null)
                    return;

                var clientIndex = controlPanelMenu.menuItems.map(function (m) { return m.type }).indexOf("client");

                if (clientIndex == -1)
                    return;

                var updatedItem = controlPanelMenu.menuItems[clientIndex];
                updatedItem.text = text;

                this.$set(controlPanelMenu.menuItems, clientIndex, updatedItem)
                    
            },
            async loadNotifications() {
                this.controllerIds = (await this.$http.get("/controller/id")).data;
                this.unacknowledgedMessages = (await this.$http.get("/clientmessage?unacknowledgedOnly=true")).data;

                var params = new URLSearchParams();

                for (var controllerId of this.controllerIds) {
                    params.append('controller_id', controllerId);
                }

                params.set('mode', "unacknowledged");

                this.unacknowledgedAlarms = (await this.$http.get(`/event/alarm?alarm_priority=Alarm&${params.toString()}`)).data;
                this.unacknowledgedAlerts = (await this.$http.get(`/event/alarm?alarm_priority=Alert&${params.toString()}`)).data;
            },
            signalRStart: function () {
                this.$notificationHub.invoke('SubscribeClientMessageUpdates', parseInt(this.$auth.user().userData.workingClientId));
            },
            async subscribeSignalr() {
                this.$loggerHubStartRegistration(async () => {
                    for (var controllerId of this.controllerIds)
                        await this.$loggerHub.invoke('SubscribeAlarms', controllerId);
                });
            },
            newMessageRecieved(newMessage) {
                console.log(newMessage)
                this.unacknowledgedMessages.push(newMessage);
                this.showNotification(this.$t("notifications.new-message-recieved"));
            },
            messageAcknowledged(messageId) {
                console.log(messageId)
                var existingIndex = this.unacknowledgedMessages.map(m => m.clientMessageId).indexOf(messageId);

                if (existingIndex == -1)
                    return;

                this.unacknowledgedMessages.splice(existingIndex, 1);
            },
            handleEventAlarmUpdated(newAlarm) {
                console.log(newAlarm)
                if (newAlarm.alarmPriority == 10) {
                    var existingIndex = this.unacknowledgedAlerts.map(m => m.eventAlarmId).indexOf(newAlarm.eventAlarmId);

                    if (existingIndex == -1) {
                        this.unacknowledgedAlerts.push(newAlarm);
                        this.showNotification(this.$t("notifications.new-alert-triggered"));
                    }

                    this.unacknowledgedAlerts.splice(existingIndex, 1);
                    
                } else if (newAlarm.alarmPriority == 20) {
                    var existingIndex = this.unacknowledgedAlarms.map(m => m.eventAlarmId).indexOf(newAlarm.eventAlarmId);

                    if (existingIndex == -1) {
                        this.unacknowledgedAlarms.push(newAlarm);
                        this.showNotification(this.$t("notifications.new-alarm-triggered"));
                    }

                    this.unacknowledgedAlarms.splice(existingIndex, 1);
                }

            },
            showNotification(text) {
                this.$notify({ type: 'alert-info', text: text });
            }
        },
        watch: {
            hasNotifications() {
                var notificationsIndex = this.menuItems.map(item => item.text).indexOf(this.$t("nav.notifications"));
                var notificationsItem = this.menuItems[notificationsIndex];

                if (!notificationsItem) return;

                notificationsItem.classes = this.hasNotifications ? ["has-notifications"] : [];

                this.$set(this.menuItems, notificationsIndex, notificationsItem);
            },
            async user() {
                await this.handleUserChange();
                this.updateClientName();
            },          

            async '$store.state.favouritesEdited'(newFavouritesEdited) {
                this.loadFavouriteData();
            },

            browsingClient() {
                var menuBrowsing = this.menuItems.find(item => item.type && item.type === 'browsing-client');
                if (menuBrowsing) {
                    if (this.browsingClient) {
                        var menuFilter = {
                            type: 'switchable-client-filter',
                            text: '',
                            icon: 'search',
                        };

                        menuBrowsing.text = this.browsingClient.name;
                        menuBrowsing.expanded = false;
                        this.$set(menuBrowsing, 'menuItems', [menuFilter]);
                        this.switchableClientsFilter = this.browsingClient.name;

                        this.$watch(() => menuFilter.text, (newValue) => {
                            this.switchableClientsFilter = newValue;
                            this.filterSwitchableClients();
                        });
                    }
                }
            },
            wide() {
                if (this.wide == false) {
                    for (var menuItem of this.menuItems) {
                        menuItem.expanded = false;
                    }
                }
            },
            async controllerIds(newValues, oldValues) {
                if (Array.isArray(oldValues))
                    for (var oldValue of oldValues)
                        await this.$loggerHub.invoke('UnsubscribeAlarms', oldValue);

                await this.subscribeSignalr();
            }
        },
        mounted: function () {
            this.$notificationHubStartRegistration(this.signalRStart.bind(this));
            this.$notificationHub.on('ClientMessageRecieved', this.newMessageRecieved);
            this.$notificationHub.on('ClientMessageAcknowledged', this.messageAcknowledged);
            this.$loggerHub.on('EventAlarmUpdated', this.handleEventAlarmUpdated);
        },
        beforeDestroy() {
            this.$notificationHub.off('ClientMessageRecieved', this.newMessageRecieved);
            this.$notificationHub.off('ClientMessageAcknowledged', this.messageAcknowledged);
            this.$loggerHub.off('EventAlarmUpdated', this.handleEventAlarmUpdated);
        },
        async created() {
            await this.handleUserChange();
            await this.loadFavouriteData();
            this.updateClientName();   


            await this.loadNotifications();

            var menuBrowsing = this.menuItems.find(item => item.type && item.type === 'browsing-client');
            if (menuBrowsing) {
                if (this.$auth.check('CLIENT_READ', 'permissions')) {
                    // Populate the browsing client menu
                    var menuFilter = menuBrowsing.menuItems.find(item => item.type && item.type === 'switchable-client-filter');
                    if (menuFilter) {
                        this.$watch(() => menuFilter.text, (newValue) => {
                            this.switchableClientsFilter = newValue;
                            this.filterSwitchableClients();
                        });
                    }
                } else {
                    // Remove browsing client menu from the side bar
                    var menuBrowsingIndex = this.menuItems.indexOf(menuBrowsing);
                    this.menuItems.splice(menuBrowsingIndex, 1);
                }
            }
        },
    }


</script>

<style scoped>

    .came {
        font-family: 'came_type';
        font-weight: 400;
        font-size: 2.75rem;
        line-height: 1;
    }

    .dropup .dropdown-toggle::after {
        display: none;
    }

    
    
</style>